// extracted by mini-css-extract-plugin
export const container = "index-module--container--R_Obn";
export const bannerBox = "index-module--bannerBox--39IQz";
export const bannerImg = "index-module--bannerImg--1Oklm";
export const bannerMain = "index-module--bannerMain--39s5k";
export const main = "index-module--main--3XwFY";
export const title = "index-module--title--1nbIj";
export const rowIconBox = "index-module--rowIconBox--fjWd1";
export const rowIcon = "index-module--rowIcon--1tFo-";
export const rowText = "index-module--rowText--17MOH";
export const tips = "index-module--tips--2RpDD";
export const jump = "index-module--jump--38gn2";
export const startBtn = "index-module--startBtn--1x31S";
export const box1 = "index-module--box1--29iMf";
export const mainTitle = "index-module--mainTitle--2W2YG";
export const coopContainer = "index-module--coopContainer--2LC7g";
export const coopBox = "index-module--coopBox--2KWVp";
export const coopBoxFlex = "index-module--coopBoxFlex--209HW";
export const coopImg = "index-module--coopImg--3N596";
export const ngtcImg = "index-module--ngtcImg--3JClR";
export const coopInfo = "index-module--coopInfo--yrsuC";
export const boxTitle = "index-module--boxTitle--rvqAQ";
export const infoList = "index-module--infoList--TfwJ8";
export const infoItem = "index-module--infoItem--3Hf9j";
export const more = "index-module--more--3xhBK";
export const rapMore = "index-module--rapMore--1lrcq";
export const ngtcMore = "index-module--ngtcMore--21K9U";
export const box3 = "index-module--box3--3CzT3";
export const aboutMore = "index-module--aboutMore--3Z7jv";
export const moreIcon = "index-module--moreIcon--21gKN";
export const box2 = "index-module--box2--3hpgr";
export const moduleTitleBox = "index-module--moduleTitleBox--2bDib";
export const moduleTitle = "index-module--moduleTitle--2SjVz";
export const prosList = "index-module--prosList--3gb3-";
export const prosItem = "index-module--prosItem--2HtTo";
export const prosItemImg = "index-module--prosItemImg--2a890";
export const prosItemTitle = "index-module--prosItemTitle--2dzNv";
export const prosItemDesc = "index-module--prosItemDesc--3Jvne";
export const prosItemDescItem = "index-module--prosItemDescItem--2e9Ja";
export const platform = "index-module--platform--18JCZ";
export const platformContent = "index-module--platformContent--H3RBr";
export const platformTitleBox = "index-module--platformTitleBox--3rSJY";
export const platformTitle = "index-module--platformTitle--t3RF5";
export const platformInfo = "index-module--platformInfo--15DAL";
export const platformInfoItem = "index-module--platformInfoItem--2Sv82";
export const platformInfoItemImg = "index-module--platformInfoItemImg--2vtEs";
export const platformInfoItemCount = "index-module--platformInfoItemCount--3j3ye";
export const platformInfoItemText = "index-module--platformInfoItemText--3yhaK";
export const box3Content = "index-module--box3Content--DP2B3";
export const diamondBg1 = "index-module--diamondBg1--3F3xf";
export const diamondBg2 = "index-module--diamondBg2--1t9wk";
export const sectionTitle = "index-module--sectionTitle--2luKM";
export const box3Desc = "index-module--box3Desc--3cYgB";
export const box4 = "index-module--box4--2UjwM";
export const box4Content = "index-module--box4Content--1req9";
export const swiper = "index-module--swiper--1aGuB";
export const swiperItem = "index-module--swiperItem--J9Z8Q";
export const list = "index-module--list--2dh2A";
export const item = "index-module--item--2nzxO";
export const itemImg = "index-module--itemImg--JvEtB";