import React, { useState, useEffect, useRef } from "react"
// import SwiperCore, { Pagination, Autoplay } from 'swiper';
import { Link } from 'gatsby'
// import { Swiper, SwiperSlide } from 'swiper/react';
import { useInViewport } from 'ahooks'
import { useCountUp } from 'react-countup';
import cx from 'classnames'
import Layout from '../components/Layout'
import Icon from '../components/Icon'
import { getLoginPath } from '../utils/util'
import MHome from '../components/MHome'
import {
  rapnet_1,
  ngtc_1,
  tips,
  rapnet_3,
  ngtc_2,
  aboutPath,
  Frame,
  Frame1,
  Frame2,
  d,
  $,
  h
} from '../utils/constant'
import * as styles from './index.module.less'
import 'swiper/swiper-bundle.css'

// SwiperCore.use([Pagination, Autoplay]);

const Home = () => {
  const [flag, setFlag] = useState(false);
  const [h5, setH5] = useState(false);
  const ref = useRef();
  // const bannerRef = useRef();
  const inViewPort = useInViewport(ref);
  // const bannerInViewPort = useInViewport(bannerRef);
  const { countUp: countUp1, start: start1 } = useCountUp({
    start: 0,
    end: 1854320,
    separator: ',',
    startOnMount: false
  });
  const { countUp: countUp2, start: start2 } = useCountUp({
    start: 0,
    end: 141569576698,
    separator: ',',
    startOnMount: false
  });
  const { countUp: countUp3, start: start3 } = useCountUp({
    start: 0,
    end: 631,
    startOnMount: false
  });

  useEffect(() => {
    if(inViewPort) {
      start1()
      start2()
      start3()
    }
  }, [inViewPort])

  const handleScroll = () => {
    const scrollheight = document.body.scrollTop === 0 ? document.documentElement.scrollTop : document.body.scrollTop;
    setFlag(scrollheight > 80)
  }
  const handleTest = () => {
    if (/(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent)) {
      setH5(true);
    }
  }

  useEffect(() => {
    handleTest()
    handleScroll()
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <div>
      {
        h5 ? (
          <MHome />
        ) : (
          <Layout
            contentClassName={styles.container}
            plainHeader={flag}
            seoProps={{ title: "首页" }}
          >
            <section>
              <div className={styles.bannerBox}>
                <div className={styles.bannerMain}>
                  <h1 className={styles.title}>一键直采全球钻石</h1>

                  <div className={styles.rowIconBox}>
                    <img className={styles.rowIcon} src={rapnet_1} alt="RAPNET"/>
                    <span className={styles.rowText}>RAPNET中国唯一战略合作伙伴</span>
                  </div>
                  <div className={styles.rowIconBox} style={{ marginTop: 24 }}>
                    <img className={styles.rowIcon} src={ngtc_1} alt="RAPNET"/>
                    <span className={styles.rowText}>NGTC全球战略合作伙伴</span>
                  </div>

                  {/* <img className={cx(styles.bannerImg, { [styles.transition]: bannerInViewPort })} src="https://wanbaorapdia.oss-cn-shenzhen.aliyuncs.com/fileserver/image/rapnetbuy/banner-2.png" alt="" /> */}
                  <img className={styles.bannerImg} src="https://wanbaorapdia.oss-cn-shenzhen.aliyuncs.com/fileserver/image/rapnetbuy/banner-2.png" alt="" />

                  <img src={tips} className={styles.tips} alt="" />

                  <a href={getLoginPath()} className={styles.startBtn}>
                    免费使用
                  </a>
                </div>
              </div>

              <div className={styles.box1}>
                <div className={styles.coopContainer}>
                  <div className={styles.coopBoxFlex}>
                    <img className={styles.coopImg} src={rapnet_3} alt="rapnet"/>
                    <div className={styles.coopInfo}>
                      <h3 className={styles.boxTitle}>RAPNET中国唯一合作伙伴</h3>
                      <ul className={styles.infoList}>
                        <li className={styles.infoItem}>- 直联全球10000+裸钻供应商</li>
                        <li className={styles.infoItem}>- 实时对接百万裸钻库存</li>
                        <li className={styles.infoItem}>- RAPNET海外履约确保交易安全</li>
                      </ul>
                    </div>
                  </div>

                  <div className={styles.coopBoxFlex}>
                    <img className={styles.ngtcImg} src={ngtc_2} alt="ngtc"/>
                    <div className={styles.coopInfo}>
                      <h3 className={styles.boxTitle}>NGTC官方深度合作伙伴</h3>
                      <ul className={styles.infoList}>
                        <li className={styles.infoItem}>- NGTC全球服务深度集成</li>
                        <li className={styles.infoItem}>- 买方可要求货源地先挑石后进口 降低掉级风险</li>
                        <li className={styles.infoItem}>- 买方可要求海外挑石 境内出证 降低成本</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles.box2}>
                <div className={styles.main}>
                  <div className={styles.moduleTitleBox}>
                    <span className={styles.moduleTitle}>独特优势</span>
                  </div>


                  <ul className={styles.prosList}>
                    <li className={styles.prosItem}>
                      <img className={styles.prosItemImg} src={Frame} alt="" />
                      <div className={styles.prosItemTitle}>品质保障</div>
                      <ul className={styles.prosItemDesc}>
                        <li className={styles.prosItemDescItem}><Icon type="icontick-fill" /> 100%天然钻石</li>
                        <li className={styles.prosItemDescItem}><Icon type="icontick-fill" /> 货源地NGTC挑⽯，规避掉级风险</li>
                        <li className={styles.prosItemDescItem}><Icon type="icontick-fill" /> 精选RapNet优质卖家，货源有保障</li>
                      </ul>
                    </li>
                    <li className={styles.prosItem}>
                      <img className={styles.prosItemImg} src={Frame1} alt="" />
                      <div className={styles.prosItemTitle}>安全透明</div>
                      <ul className={styles.prosItemDesc}>
                        <li className={styles.prosItemDescItem}><Icon type="icontick-fill" /> 全程担保交易，降低跨境直采贸易风险</li>
                        <li className={styles.prosItemDescItem}><Icon type="icontick-fill" /> RAPNET海外运营，物流运输全程透明</li>
                        <li className={styles.prosItemDescItem}><Icon type="icontick-fill" /> 钻交所进关，贸易合规</li>
                      </ul>
                    </li>
                    <li className={styles.prosItem}>
                      <img className={styles.prosItemImg} src={Frame2} alt="" />
                      <div className={styles.prosItemTitle}>高效便捷</div>
                      <ul className={styles.prosItemDesc}>
                        <li className={styles.prosItemDescItem}><Icon type="icontick-fill" /> 海量库存，⼀站式解决挑货需求</li>
                        <li className={styles.prosItemDescItem}><Icon type="icontick-fill" /> 实时库存⼀键下单，省时省⼒</li>
                        <li className={styles.prosItemDescItem}><Icon type="icontick-fill" /> 电脑、手机均可下单，实时追踪订单进度</li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>

              <div className={styles.platform}>
                <div className={styles.platformContent}>
                  <div className={styles.platformTitleBox}>
                    <span className={styles.platformTitle}>在我们平台</span>
                  </div>

                  <ul className={styles.platformInfo} ref={ref}>
                    <li className={styles.platformInfoItem}>
                      <img className={styles.platformInfoItemImg} src={d} alt="" />
                      <div className={styles.platformInfoItemCount}>{countUp1}</div>
                      <div className={styles.platformInfoItemText}>钻石总数</div>
                    </li>
                    <li className={styles.platformInfoItem}>
                      <img className={styles.platformInfoItemImg} src={$} alt="" />
                      <div className={styles.platformInfoItemCount}>¥{countUp2}</div>
                      <div className={styles.platformInfoItemText}>钻石总价值</div>
                    </li>
                    <li className={styles.platformInfoItem}>
                      <img className={styles.platformInfoItemImg} src={h} alt="" />
                      <div className={styles.platformInfoItemCount}>{countUp3}</div>
                      <div className={styles.platformInfoItemText}>商家</div>
                    </li>
                  </ul>
                </div>
              </div>

              <div className={styles.box3}>
                <div className={styles.box3Content}>
                  <div className={styles.moduleTitleBox}>
                    <span className={styles.moduleTitle}>关于我们</span>
                  </div>

                  <div className={styles.box3Desc}>RAPNETBUY(宝猫科技) 是一站式B2B钻石交易服务平台, 为全球最大的钻石交易信息网站RAPNET中国区唯一官方合作伙伴，国家珠宝玉石质量监督检验管理中心(NGTC) 战略合作伙伴，致力于为中国机构买家提供便捷安全的B2B钻石交易和增值服务，使中国机构买家可以方便,安全,快捷地直采全球钻石商品。</div>
                  <Link className={cx(styles.more, styles.aboutMore)} to={aboutPath}>
                    了解更多
                    <Icon type="iconarrows2" className={styles.moreIcon} />
                  </Link>

                  <div className={styles.diamondBg1} />
                  <div className={styles.diamondBg2} />
                </div>
              </div>

              {/* <div className={styles.box4}>
                <div className={styles.box4Content}>
                  <div className={styles.sectionTitle}>战略合作伙伴</div>

                  <div className={styles.swiper}>
                    <Swiper
                      // autoplay={{ delay: 3000 }}
                      spaceBetween={16}
                      // pagination={{ clickable: true }}
                    >
                      <SwiperSlide className={styles.swiperItem}>
                        <div className={styles.list}>
                          <div className={styles.item}>
                            <img className={styles.itemImg} src={rapnet_2} alt="" />
                          </div>
                          <div className={styles.item}>
                            <img className={styles.itemImg} src={part2} alt="" />
                          </div>
                          <div className={styles.item}>
                            <img className={styles.itemImg} src={part3} alt="" />
                          </div>
                          <div className={styles.item}>
                            <img className={styles.itemImg} src={part4} alt="" />
                          </div>
                          <div className={styles.item}>
                            <img className={styles.itemImg} src={part5} alt="" />
                          </div>
                        </div>
                      </SwiperSlide>
                    </Swiper>
                  </div>
                </div>
              </div> */}
            </section>
          </Layout>
        )
      }
    </div>
  )
}

export default Home
