import React, { useEffect, useRef } from "react"
import * as styles from './MHome.module.less';
import classNames from 'classnames';
import { useCountUp } from 'react-countup';
import Icon from './Icon';
import { getLoginPath } from '../utils/util';
import { useInViewport } from "ahooks"
const MHome = () => {
  const ref = useRef();
  const inViewPort = useInViewport(ref);
  useEffect(() => {
    if(inViewPort) {
      start1()
      start2()
      start3()
    }
  }, [inViewPort])

  const { countUp: countUp1, start: start1 } = useCountUp({
    start: 0,
    end: 854320,
    separator: ',',
    startOnMount: false
  });
  const { countUp: countUp2, start: start2 } = useCountUp({
    start: 0,
    end: 41569576698,
    separator: ',',
    startOnMount: false
  });
  const { countUp: countUp3, start: start3 } = useCountUp({
    start: 0,
    end: 492,
    startOnMount: false
  });
  return (
    <div>
      <div className={styles.topBox}>
        <div className={classNames(styles.logoBox, styles.mb36)}>
          <img className={styles.logo} src="https://wb-static-sh.oss-cn-shanghai.aliyuncs.com/logo-white.png" alt=""/>
          <span className={styles.diver} />
          <span className={classNames(styles.font20, styles.font600)}>RAPNETBUY（宝猫科技）</span>
        </div>
        <img className={classNames(styles.imgLogo, styles.mb24)} src="https://wb-static-sh.oss-cn-shanghai.aliyuncs.com/home/h5/%E4%B8%80%E9%94%AE%E7%9B%B4%E9%87%87%E5%85%A8%E7%90%83%E9%92%BB%E7%9F%B3.png" alt=""/>
        <div className={classNames(styles.logoBox, styles.mb16)}>
          <img className={classNames(styles.iconSmall, styles.mr8)} src="https://wb-static-sh.oss-cn-shanghai.aliyuncs.com/home/h5/r.png" alt=""/>
          <span className={classNames(styles.font18, styles.font500)}>RAPNET中国唯一战略合作伙伴</span>
        </div>
        <div className={styles.logoBox}>
          <img className={classNames(styles.iconSmall, styles.mr8)} src="https://wb-static-sh.oss-cn-shanghai.aliyuncs.com/home/h5/n.png" alt=""/>
          <span className={classNames(styles.font18, styles.font500)}>NGTC官方深度合作伙伴</span>
        </div>
        <img className={styles.imgTip} src="https://wb-static-sh.oss-cn-shanghai.aliyuncs.com/home/h5/6%E4%B8%AA%E6%9C%88%E5%85%8D%E8%B4%B9.png" alt=""/>
        <a href={getLoginPath()} className={styles.btn}>
          免费使用
        </a>
      </div>
      <div className={classNames(styles.midBox, styles.bgff)}>
        <div className={styles.midItem}>
          <img className={styles.iconBig} src="https://wb-static-sh.oss-cn-shanghai.aliyuncs.com/home/h5/r%E5%A4%A7.png" alt=""/>
          <div className={classNames(styles.font14)}>
            <div className={classNames(styles.font600, styles.font18, styles.mb8)}>RAPNET中国唯一合作伙伴</div>
            <div className={styles.mb8}>- 直联全球10000+裸钻供应商</div>
            <div className={styles.mb8}>- 实时对接百万裸钻库存</div>
            <div className={styles.mb8}>- RAPNET海外履约确保交易安全</div>
          </div>
        </div>
        <div className={styles.midItem}>
          <div className={styles.font14}>
            <div className={classNames(styles.font600, styles.font18, styles.mb8)}>NGTC官方深度合作伙伴</div>
            <div className={styles.mb8}>- NGTC全球服务深度集成</div>
            <div className={styles.mb8}>- 货源地先挑石后进口 减免掉级风险</div>
            <div className={styles.mb8}>- 海外挑石 境内出证 降低成本</div>
          </div>
          <img className={styles.iconBig2} src="https://wb-static-sh.oss-cn-shanghai.aliyuncs.com/home/h5/n%E5%A4%A7.png" alt=""/>
        </div>
      </div>
      <div className={classNames(styles.midBox, styles.bgf8)}>
        <div className={styles.tip}>
          <div className={classNames(styles.tipTitle, styles.mb32)}>
            独特优势
          </div>
          <div className={classNames(styles.tipItem, styles.mb32)}>
            <div className={classNames(styles.tip, styles.mb8)}>
              <img className={classNames(styles.iconTip, styles.mb12)} src="https://wb-static-sh.oss-cn-shanghai.aliyuncs.com/home/h5/%E5%93%81%E8%B4%A8%E4%BF%9D%E9%9A%9C.png" alt=""/>
              <div className={classNames(styles.font16, styles.font600)}>品质保障</div>
            </div>
            <div className={styles.mb10}><Icon type="icontick-fill" className={styles.icontick} />100%天然钻石</div>
            <div className={styles.mb10}><Icon type="icontick-fill" className={styles.icontick} />货源地NGTC挑⽯，规避掉级风险</div>
            <div className={styles.mb10}><Icon type="icontick-fill" className={styles.icontick} />精选RapNet优质卖家，货源有保障</div>
          </div>
          <div className={classNames(styles.tipItem, styles.mb32)}>
            <div className={classNames(styles.tip, styles.mb8)}>
              <img className={classNames(styles.iconTip, styles.mb12)} src="https://wb-static-sh.oss-cn-shanghai.aliyuncs.com/home/h5/%E5%AE%89%E5%85%A8%E9%80%8F%E6%98%8E.png" alt=""/>
              <div className={classNames(styles.font16, styles.font600)}>安全透明</div>
            </div>
            <div className={styles.mb10}><Icon type="icontick-fill" className={styles.icontick} />全程担保交易, 降低跨境直采贸易风险</div>
            <div className={styles.mb10}><Icon type="icontick-fill" className={styles.icontick} />RAPNET海外运营, 物流运输全程透明</div>
            <div className={styles.mb10}><Icon type="icontick-fill" className={styles.icontick} />钻交所进关，贸易合规</div>
          </div>
          <div className={styles.tipItem}>
            <div className={classNames(styles.tip, styles.mb8)}>
              <img className={classNames(styles.iconTip, styles.mb12)} src="https://wb-static-sh.oss-cn-shanghai.aliyuncs.com/home/h5/%E9%AB%98%E6%95%88%E4%BE%BF%E6%8D%B7.png" alt=""/>
              <div className={classNames(styles.font16, styles.font600)}>高效便捷</div>
            </div>
            <div className={styles.mb10}><Icon type="icontick-fill" className={styles.icontick} />海量库存，⼀站式解决挑货需求</div>
            <div className={styles.mb10}><Icon type="icontick-fill" className={styles.icontick} />实时库存⼀键下单，省时省⼒</div>
            <div className={styles.mb10}><Icon type="icontick-fill" className={styles.icontick} />精选RapNet优质卖家，货源有保障</div>
          </div>
        </div>
      </div>
      <div className={styles.numBox}>
        <div className={styles.tip}>
          <div className={styles.numTitle}>
            在我们平台
          </div>
        </div>
        <div className={styles.numItemBox} ref={ref}>
          <div className={styles.numItem}>
            <img className={styles.numImg} src="https://wb-static-sh.oss-cn-shanghai.aliyuncs.com/home/h5/%E9%92%BB%E7%9F%B3%E6%80%BB%E6%95%B0.png" alt=""/>
            <div className={classNames(styles.mb8, styles.font600)}>{countUp1}</div>
            <div className={styles.font14}>钻石总数</div>
          </div>
          <div className={styles.numItem}>
            <img className={styles.numImg} src="https://wb-static-sh.oss-cn-shanghai.aliyuncs.com/home/h5/%E9%92%BB%E7%9F%B3%E6%80%BB%E4%BB%B7%E5%80%BC.png" alt=""/>
            <div className={classNames(styles.mb8, styles.font600)}>¥{countUp2}</div>
            <div className={styles.font14}>钻石总价值</div>
          </div>
          <div className={styles.numItem}>
            <img className={styles.numImg} src="https://wb-static-sh.oss-cn-shanghai.aliyuncs.com/home/h5/%E5%95%86%E5%AE%B6.png" alt=""/>
            <div className={classNames(styles.mb8, styles.font600)}>{countUp3}</div>
            <div className={styles.font14}>商家</div>
          </div>
        </div>
      </div>
      <div className={styles.midBox}>
        <div className={styles.tip}>
          <div className={classNames(styles.tipTitle, styles.mb20)}>
            关于我们
          </div>
          <div className={styles.aboutText}>
            RAPNETBUY是一站式B2B钻石交易服务平台, 为全球最大的钻石交易信息网站RAPNET中国区唯一官方合作伙伴，国家珠宝玉石质量监督检验管理中心(NGTC) 战略合作伙伴，致力于为中国机构买家提供便捷安全的B2B钻石交易和增值服务，使中国机构买家可以方便,安全,快捷地直采全球钻石商品。
          </div>
        </div>
      </div>
      <div className={styles.footerBox}>
        <div className={classNames(styles.font14, styles.font600, styles.mb16)}>联系我们</div>
        <div className={styles.call}>
          <div className={styles.mr18}>电话：13266166161</div>
          <div>邮箱：service@rapnetbuy.com</div>
        </div>
        <div className={styles.footer}>Copyright © 2021 wanbaomarket.com 版权所有 | 沪ICP备18013404号-7</div>
      </div>
    </div>
  )
};

export default MHome;