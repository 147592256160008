// extracted by mini-css-extract-plugin
export const topBox = "MHome-module--topBox--T2P78";
export const logoBox = "MHome-module--logoBox--3oWGl";
export const logo = "MHome-module--logo--3rRE3";
export const imgLogo = "MHome-module--imgLogo--2g9a2";
export const diver = "MHome-module--diver--2fXc0";
export const iconSmall = "MHome-module--iconSmall--14dy0";
export const iconBig = "MHome-module--iconBig--1Fyzh";
export const iconBig2 = "MHome-module--iconBig2--1Vf6k";
export const mr8 = "MHome-module--mr8--RoCIi";
export const font10 = "MHome-module--font10--hF7fI";
export const font14 = "MHome-module--font14--aR6V8";
export const font16 = "MHome-module--font16--17myC";
export const font18 = "MHome-module--font18--17dOy";
export const font20 = "MHome-module--font20--1JMKZ";
export const font40 = "MHome-module--font40--s-2pu";
export const font500 = "MHome-module--font500--2aboi";
export const font600 = "MHome-module--font600--31bqi";
export const mb8 = "MHome-module--mb8--29Q3r";
export const mb10 = "MHome-module--mb10--OHWuF";
export const mb12 = "MHome-module--mb12--1jLkZ";
export const mb16 = "MHome-module--mb16--3B9AQ";
export const mb20 = "MHome-module--mb20--Ugf5G";
export const mb24 = "MHome-module--mb24--2MJc-";
export const mb32 = "MHome-module--mb32--1gHAw";
export const mb36 = "MHome-module--mb36--jDjG6";
export const mr18 = "MHome-module--mr18--31KtB";
export const cf = "MHome-module--cf--3f-y6";
export const imgTip = "MHome-module--imgTip--11mxw";
export const jump = "MHome-module--jump--FeTyG";
export const btn = "MHome-module--btn--e3jGy";
export const midBox = "MHome-module--midBox--1iH2L";
export const midItem = "MHome-module--midItem--1VQO_";
export const bgff = "MHome-module--bgff--22JFE";
export const bgf8 = "MHome-module--bgf8--KJWyg";
export const tipTitle = "MHome-module--tipTitle--36YHf";
export const tip = "MHome-module--tip--KzXlo";
export const iconTip = "MHome-module--iconTip--1w88B";
export const icontick = "MHome-module--icontick--17EjU";
export const tipItem = "MHome-module--tipItem--2QUE8";
export const numBox = "MHome-module--numBox--2dlF1";
export const numTitle = "MHome-module--numTitle--wlHSG";
export const numItem = "MHome-module--numItem--3lsa_";
export const numItemBox = "MHome-module--numItemBox--2RyHs";
export const numImg = "MHome-module--numImg--3vwQ6";
export const aboutText = "MHome-module--aboutText--346e5";
export const footerBox = "MHome-module--footerBox--2Frek";
export const call = "MHome-module--call--o_PdN";
export const footer = "MHome-module--footer--1vIyu";